import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '../entities/UnreachableCaseError';

type BodySize = 'tiny' | 'small' | 'medium' | 'regular';

interface Props extends HTMLAttributes<HTMLParagraphElement> {
    size?: BodySize;
}

const Body: FC<Props> = ({ children, size, ...props }) => (
    <Inner size={size} {...props}>
        {children}
    </Inner>
);

const tinyCSS = css`
    font-size: 10px;
    line-height: ${({ theme }) => theme.lineHeights.tiny};

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        font-size: ${({ theme }) => theme.fontSizes.tiny};
    }
`;

const regularCSS = css`
    font-size: ${({ theme }) => theme.fontSizes.regular};
    line-height: ${({ theme }) => theme.lineHeights.large};
`;

const mediumCSS = css`
    font-size: 13px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

const getCss = (size: BodySize) => {
    switch (size) {
        case 'tiny':
            return tinyCSS;
        case 'small':
            return 'font-size: 15px';
        case 'medium':
            return mediumCSS;
        case 'regular':
            return regularCSS;
        default:
            throw new UnreachableCaseError(size);
    }
};

const Inner = styled.p<Props>`
    ${({ size = 'regular' }) => getCss(size)};

    p {
        margin-bottom: 16px;
    }
`;

export default Body;
