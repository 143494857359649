import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                title
                description
                keywords
                author
            }
        }
    }
`;

interface Props {
    title?: string;
    description?: string | null;
    keywords?: string[];
    metaImage?: string;
}

const isBrowser = typeof window !== 'undefined';

const SEO: FC<Props> = ({ title, description, keywords, metaImage }) => {
    const {
        site: { siteMetadata },
    } = useStaticQuery(query);

    const metaDescription = description ?? siteMetadata.description;
    const metaTitle = title ?? siteMetadata.title;
    const metaKeywords = keywords ?? siteMetadata.keywords;
    const { locale } = useIntl();

    return (
        <Helmet
            titleTemplate={title !== '/' ? `${siteMetadata.title} | %s` : ''}
            title={metaTitle}
        >
            {/* Meta tags */}
            <html lang={`${locale}`} />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords.join(',')} />
            <meta name="author" content={siteMetadata.author} />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1.0"
            />
            <link rel="stylesheet" href="https://use.typekit.net/vpq3bxd.css" />

            {/* favicons */}
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />
            {/* <link rel="manifest" href="/site.webmanifest" /> */}

            {/* Open Graph */}
            <meta property="og:locale" content={locale} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:sitename" content={siteMetadata.title} />
            {isBrowser && (
                <meta property="og:url" content={window.location.href} />
            )}
            {metaImage && <meta property="og:image" content={metaImage} />}
        </Helmet>
    );
};

export default SEO;
