interface zIndices {
    [key: string]: number;
}

const zIndices: zIndices = {
    hide: -1,
    overlap: 1,
    modal: 2,
    menu: 3,
    fullScreen: 4,
};

const theme = {
    colors: {
        domas0: '#AFFF6C',
        domas1: '#86FD1E ',
        domas2: '#74FF00',
        domas3: '#76FD00',
        grey: '#FBFFF8',
        border: '#707070',
        black: '#000',
        black2: '#000000B2',
        white: '#fff',
        white2: '#FBFFF8',
        focus: '#005fcc',
    },
    backgroundColors: {
        worldPulse:
            'radial-gradient( \
            closest-side at 50% 50%, \
            #86fd1e 0%, \
            #fbfff8 97%, \
            #ffffff 100% \
        )',
    },
    border: '1px solid #000',
    zIndices,
    fontWeights: {
        thin: 200,
        light: 300,
        regular: 400,
    },
    fontSizes: {
        tiny: '12px' /* extra info in window component (left) & footer */,
        small:
            '15px' /* TODO: also extra info in window component, ask Domas. */,
        medium: '16px' /* highlighted photo */,
        regular: '17px' /* regular/body texts */,
        caps: '18px' /* caps headers in window & menu */,
        world: '30px' /* world text */,
        header: '39px' /* header */,
        huge: '46px' /* shows in fullscreen window */,
    },
    letterSpacings: {
        default: '0',
        expanded: '12px',
    },
    /* space between paragraphs */
    lineHeights: {
        default: 1,
        tiny: '13px' /* tiny texts and footer */,
        small: '18px' /* body text */,
        medium: '19px' /* overview title */,
        large: '21px' /* caps headers in window & menu */,
        world: '36px' /* text in world */,
        header: '47px',
        huge: '50px' /* shows in fullscreen modal */,
    },
    mediaQueries: {
        small: '576px',
        medium: '768px',
        large: '992px',
        xl: '1280px',
    },
};

export default theme;
