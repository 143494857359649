import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import theme from '../constants/theme';
import GlobalStyle from '../globalStyling';
import Contact, { ContactDetails } from './Contact';
import Menu from './Menu';
import { useIntl } from 'gatsby-plugin-intl';
import SEO from './Seo';

interface Props {
    title?: string;
    description?: string;
    keywords?: string[];
    metaImage?: string;
    showFooter?: boolean;
    showBackgroundGradient?: boolean;
    showBackgroundTriangle?: boolean;
    overflowYHidden?: boolean;
}

interface DataProps {
    navigation: {
        edges: [
            {
                node: {
                    childMarkdownRemark: {
                        frontmatter: {
                            [key: string]: {
                                about_me: string;
                                archive: string;
                                contact: string;
                                upcoming: string;
                            };
                        };
                    };
                };
            }
        ];
    };
    contact: {
        edges: [
            {
                node: {
                    childMarkdownRemark: {
                        frontmatter: {
                            contact: ContactDetails;
                        };
                    };
                };
            }
        ];
    };
}

const Layout: FC<Props> = ({
    children,
    title,
    description,
    keywords,
    metaImage,
    showFooter = false,
    showBackgroundGradient = false,
    showBackgroundTriangle = false,
    overflowYHidden = false,
}) => {
    const { locale } = useIntl();
    const { navigation, contact } = useStaticQuery<DataProps>(query);

    const navigationData = {
        ...navigation.edges[0].node.childMarkdownRemark.frontmatter[
            `${locale}Navigation`
        ],
    };

    const contactData = {
        ...contact.edges[0].node.childMarkdownRemark.frontmatter.contact,
    };

    return (
        <>
            <SEO
                title={title ? title : undefined}
                description={description ? description : undefined}
                keywords={keywords ? keywords : undefined}
                metaImage={metaImage ? metaImage : undefined}
            />
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                <Container
                    showBackgroundTriangle={showBackgroundTriangle}
                    overflowYHidden={overflowYHidden}
                >
                    <GradientBackground
                        showBackgroundGradient={showBackgroundGradient}
                    >
                        {showBackgroundTriangle && <DiagonalLine />}
                    </GradientBackground>
                    <Navigation>
                        <Link to={`/${locale}`}>
                            <Heading>domas van wijk</Heading>
                        </Link>
                        <Menu
                            contactData={contactData}
                            navigationData={navigationData}
                        />
                    </Navigation>
                    {children}
                    {showFooter && (
                        <Footer>
                            <Contact variant="footer" data={contactData} />
                        </Footer>
                    )}
                </Container>
            </ThemeProvider>
        </>
    );
};

const query = graphql`
    {
        navigation: allFile(
            filter: { absolutePath: { regex: "/navigation/i" } }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            enNavigation {
                                about_me
                                archive
                                contact
                                upcoming
                            }
                            nlNavigation {
                                about_me
                                archive
                                contact
                                upcoming
                            }
                        }
                    }
                }
            }
        }
        contact: allFile(filter: { absolutePath: { regex: "/contact/i" } }) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            contact {
                                name
                                phone_number
                                email
                                facebook
                                instagram
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Container = styled.div<{
    showBackgroundTriangle: boolean;
    overflowYHidden: boolean;
}>`
    width: 100vw;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: ${({ overflowYHidden }) =>
        overflowYHidden ? 'hidden' : 'visible'};

    :before {
        @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
            ${({ showBackgroundTriangle }) =>
                showBackgroundTriangle &&
                css`
                    content: '';
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    border-right: ${({ theme }) => theme.border};
                `};
        }
    }
`;

const DiagonalLine = styled.div`
    display: none;

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        z-index: ${({ theme }) => theme.zIndices.hide};
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to top left,
            transparent calc(50% - 1px),
            ${({ theme }) => theme.colors.black},
            transparent calc(50% + 1px)
        );
    }
`;

const GradientBackground = styled.div<{ showBackgroundGradient: boolean }>`
    display: none;

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        z-index: ${({ theme }) => theme.zIndices.hide};
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        clip-path: polygon(100% 0, 0 0, 0% 100%);
        background: ${({ showBackgroundGradient, theme }) =>
            showBackgroundGradient
                ? `transparent linear-gradient(180deg,
            ${theme.colors.white} 0%,
            ${theme.colors.domas3} 100%) 0% 0% no-repeat padding-box;`
                : 'transparent'};
    }
`;

const Navigation = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    padding: 0 13px;
    text-align: center;
    z-index: ${({ theme }) => theme.zIndices.menu};
    width: 100%;

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        padding: 0;
        padding-left: 37px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        width: initial;
        text-align: initial;
        padding: 0;
        padding-left: 56px;
        flex-direction: row;
    }
`;

const Heading = styled.h1`
    font-family: 'Sathu', sans-serif;
    font-size: 39px;
    text-transform: uppercase;
    letter-spacing: calc(100vw / 7);
    margin: 0;
    margin-top: 12px;
    margin-bottom: 24px;
    display: flex;
    font-weight: 300;
    justify-content: space-between;

    @media screen and (min-width: 706px) {
        letter-spacing: calc(100vw / 5.3);
    }
    @media screen and (min-width: 815px) {
        letter-spacing: calc(100vw / 22);
    }
    @media screen and (min-width: 1235px) {
        letter-spacing: 62px;
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    height: 58px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    font-size: ${({ theme }) => theme.fontSizes.tiny};
    padding: 0 17px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        padding: 0 58px;
    }
`;

export default Layout;
