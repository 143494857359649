import { useState, useEffect } from 'react';
import { debounce } from 'debounce';

interface WindowSize {
    width?: number;
    height?: number;
}

// https://usehooks.com/useWindowSize/
const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const changeWindowSize = () =>
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });

        window.addEventListener('resize', debounce(changeWindowSize, 250));

        changeWindowSize();

        return () => {
            window.removeEventListener(
                'resize',
                debounce(changeWindowSize, 250)
            );
        };
    }, []);

    return windowSize;
};

export default useWindowSize;
