import React, { FC } from 'react';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import { ImMail } from 'react-icons/im';
import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '../entities/UnreachableCaseError';
import Body from './Body';

type ContactVariant = 'window' | 'footer';

export type ContactDetails = {
    name: string;
    phone_number?: string;
    email?: string;
    facebook?: string;
    instagram?: string;
};

type Props = {
    variant: ContactVariant;
    data: ContactDetails;
};

const Contact: FC<Props> = ({ variant, data }) => {
    const { name, phone_number, email, facebook, instagram } = data;

    return (
        <Container variant={variant}>
            <div>
                <Body size={variant === 'footer' ? 'tiny' : 'medium'}>
                    {name}
                </Body>
                {phone_number && (
                    <Body size={variant === 'footer' ? 'tiny' : 'medium'}>
                        <a href={`tel:${phone_number}`}>Tel {phone_number}</a>
                    </Body>
                )}
                {email && (
                    <Body size={variant === 'footer' ? 'tiny' : 'medium'}>
                        <a href={`mailto: ${email}`}>mail: {email}</a>
                    </Body>
                )}
            </div>
            {(email || facebook || instagram) && (
                <Icons variant={variant}>
                    {facebook && (
                        <a href={facebook}>
                            <FaFacebookSquare />
                        </a>
                    )}
                    {instagram && (
                        <a href={instagram}>
                            <FaInstagram />
                        </a>
                    )}
                    {email && (
                        <a href={`mailto: ${email}`}>
                            <ImMail />
                        </a>
                    )}
                </Icons>
            )}
        </Container>
    );
};

const windowCSS = css`
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 100%;

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        min-height: unset;
        margin-top: 18px;
        width: 429px;
        justify-content: space-between;
        flex-direction: row;
    }
`;

const footerCSS = css`
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;

const getContainerCSS = (variant: ContactVariant) => {
    switch (variant) {
        case 'window':
            return windowCSS;
        case 'footer':
            return footerCSS;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const Container = styled.div<Pick<Props, 'variant'>>`
    ${({ variant }) => getContainerCSS(variant)}

    display: flex;
`;

const Icons = styled.div<Pick<Props, 'variant'>>`
    display: flex;
    height: 100%;
    justify-content: space-between;
    min-width: 60px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        min-width: 100px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        margin-right: ${({ variant }) =>
            variant === 'footer' ? 'auto' : '38px'};
    }

    svg {
        height: 12px;
        width: 12px;

        @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
            height: 21px;
            width: 21px;
        }
    }
`;

export default Contact;
