import { navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC, HTMLAttributes } from 'react';
import { Rnd } from 'react-rnd';
import styled, { css } from 'styled-components';
import Title from './Title';

interface Props extends HTMLAttributes<HTMLDivElement> {
    windowTitle: string;
    isDraggable?: boolean;
    isFullScreen?: boolean;
    noTopBarColor?: boolean;
    onClose?: () => void;
}

const Window: FC<Props> = ({
    children,
    windowTitle,
    isDraggable = false,
    isFullScreen = false,
    noTopBarColor = false,
    onClose,
    ...props
}) => {
    const Container = isDraggable ? DraggableContainer : StaticContainer;
    const { locale } = useIntl();

    const handleClose = () => {
        onClose ? onClose() : navigate(`/${locale}`);
    };

    return (
        <Container
            isFullScreen={isFullScreen}
            noTopBarColor={noTopBarColor}
            {...props}
        >
            <TopBar noTopBarColor={noTopBarColor}>
                <Title>{windowTitle}</Title>
                <Close onClick={handleClose}>
                    <CrossContainer>
                        <Cross />
                    </CrossContainer>
                </Close>
            </TopBar>
            {children}
        </Container>
    );
};

const DraggableContainer = styled(Rnd).attrs({
    default: {
        x: 225,
        y: 80,
        width: '743px',
        height: '186px',
    },
    minWidth: 743,
    minHeight: 186,
    maxHeight: 1000,
    maxWidth: 1000,
})`
    border: ${({ theme }) => theme.border};
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white};
    align-items: center;
    z-index: ${({ theme }) => theme.zIndices.modal};

    display: none !important;

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        display: flex !important;
    }
`;

const StaticContainer = styled.div<Omit<Props, 'windowTitle'>>`
    background: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => theme.border};
    width: calc(100vw - 32px);
    margin: 0 16px 58px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        width: 734px;
        border: ${({ theme }) => theme.border};
        ${({ noTopBarColor, theme }) =>
            noTopBarColor &&
            css`
                & :hover ${TopBar} {
                    background-color: ${theme.colors.domas3};
                }
            `};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        z-index: ${({ theme }) => theme.zIndices.overlap};
        margin: 0 0 24px;
    }

    ${({ isFullScreen }) =>
        isFullScreen &&
        css`
            margin-bottom: 0;
            width: 100vw;
            min-height: 100vh;
            position: absolute;
            top: 0;
            z-index: ${({ theme }) => theme.zIndices.fullScreen};

            @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
                width: 100vw;
            }

            @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
                z-index: ${({ theme }) => theme.zIndices.fullScreen};
            }
        `};
`;

const TopBar = styled.div<Pick<Props, 'noTopBarColor'>>`
    display: flex;
    min-height: 3.2rem;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.domas3};
    border-bottom: ${({ theme }) => theme.border};
    position: relative;
    align-items: center;
    padding-left: 1.8rem;
    padding-right: 4rem;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        ${({ noTopBarColor }) =>
            noTopBarColor && 'background-color: transparent'};
        min-height: none;
        max-height: 3.2rem;
    }
`;

const Close = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 3.2rem;
    border-left: 1px solid ${({ theme }) => theme.colors.black};
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: ${({ theme }) => theme.zIndices.overlap};
    background: ${({ theme }) => theme.colors.white};
`;

const pseudoCSS = css`
    background-color: ${({ theme }) => theme.colors.black};
    content: '';
    height: 2.5rem;
    width: 1px;
    position: absolute;
    top: 3px;
    right: 50%;
`;

const CrossContainer = styled.div`
    height: 3.1rem;
    position: relative;
`;

const Cross = styled.div`
    :before {
        ${pseudoCSS};
        transform: rotate(135deg);
    }

    :after {
        ${pseudoCSS};
        transform: rotate(-135deg);
    }
`;

export default Window;
