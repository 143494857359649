import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import Window from './Window';
import Contact, { ContactDetails } from './Contact';
import useWindowSize from '../hooks/useWindowSize';
import { useIntl } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';

type Device = 'mobile' | 'desktop';

type Props = {
    contactData: ContactDetails;
    navigationData: {
        about_me: string;
        archive: string;
        contact: string;
        upcoming: string;
    };
};

const Menu: FC<Props> = ({ navigationData, contactData }) => {
    const { locale } = useIntl();
    const [navActive, toggleNavActive] = useState(false);
    const [contactActive, toggleContact] = useState(false);

    const { about_me, archive, contact, upcoming } = navigationData;

    const { width } = useWindowSize();

    const changeLocale = (lang: string) => {
        const pathname = window?.location.pathname;
        const toReplace = pathname.includes(locale) ? locale : '/';
        const newPath = pathname.replace(toReplace, lang);

        navigate(newPath);
    };

    const renderMenuLanguage = (device: Device) => (
        <MenuLanguage device={device}>
            <LanguageOption
                activeLanguage={locale === 'nl'}
                onClick={() => changeLocale('nl')}
            >
                NL
            </LanguageOption>
            &nbsp;/&nbsp;
            <LanguageOption
                activeLanguage={locale === 'en'}
                onClick={() => changeLocale('en')}
            >
                EN
            </LanguageOption>
        </MenuLanguage>
    );

    const handleClick = (path: string) => {
        width && width < 991
            ? setTimeout(() => navigate(`/${locale}${path}`), 400)
            : navigate(`/${locale}${path}`);
    };

    return (
        <>
            <Container>
                <Hamburger onClick={() => toggleNavActive(!navActive)}>
                    <HamburgerIcon />
                </Hamburger>
                <MenuList>
                    <MenuListItems navActive={navActive}>
                        {renderMenuLanguage('mobile')}
                        <HoverArea>
                            <MenuItem>
                                <CombinedButtonItem>
                                    <MenuLink
                                        onClick={() =>
                                            handleClick(`/about/about-me`)
                                        }
                                    >
                                        *{about_me}
                                    </MenuLink>
                                </CombinedButtonItem>
                                <CombinedButtonItem>
                                    {renderMenuLanguage('desktop')}
                                </CombinedButtonItem>
                            </MenuItem>
                        </HoverArea>
                        <HoverArea>
                            <MenuItem>
                                <MenuLink
                                    onClick={() =>
                                        handleClick(`/upcoming-world`)
                                    }
                                >
                                    *{upcoming}
                                </MenuLink>
                            </MenuItem>
                        </HoverArea>
                        <HoverArea>
                            <MenuItem>
                                <MenuLink
                                    onClick={() =>
                                        handleClick(`/archive-overview`)
                                    }
                                >
                                    *{archive}
                                </MenuLink>
                            </MenuItem>
                        </HoverArea>
                        <HoverArea contact>
                            <MenuItem>
                                <MenuLink
                                    onClick={() => {
                                        toggleContact(!contactActive);
                                        width &&
                                            width > 991 &&
                                            toggleNavActive(!navActive);
                                    }}
                                >
                                    *{contact}
                                </MenuLink>
                            </MenuItem>
                        </HoverArea>
                        {contactActive && (
                            <ContactMenuItem>
                                <Contact variant="window" data={contactData} />
                            </ContactMenuItem>
                        )}
                    </MenuListItems>
                </MenuList>
            </Container>
            {contactActive && (
                <Window
                    windowTitle="*contact"
                    isDraggable
                    onClose={() => toggleContact(!contactActive)}
                >
                    <Contact variant="window" data={contactData} />
                </Window>
            )}
        </>
    );
};

const HamburgerIcon: FC = () => (
    <>
        <HamburgerLine />
        <HamburgerLine />
        <HamburgerLine />
        <HamburgerLine />
    </>
);

const HamburgerLine: FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="39.31"
        height="1"
        viewBox="0 0 39.31 1"
    >
        <line
            x2="39.31"
            transform="translate(0 0.5)"
            fill="none"
            stroke="#000"
            strokeWidth="1"
        />
    </svg>
);

const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.overlap};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        align-self: flex-start;
        position: absolute;
        top: 120px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        top: 80px;
    }
`;

const Hamburger = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 63px;
    height: 59px;
    border: ${({ theme }) => theme.border};
    padding: 16px 12px;
    background-color: ${({ theme }) => theme.colors.white};
`;

const MenuList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(100% - 19px);

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        overflow: unset;
        position: relative;
        top: unset;
        justify-content: flex-start;
        margin-left: -1px;
    }
`;

const MenuListItems = styled.div<{ navActive: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    opacity: ${({ navActive }) => (navActive ? '1' : '0')};
    visibility: ${({ navActive }) => (navActive ? 'visible' : 'hidden')};
    max-width: ${({ navActive }) => (navActive ? '285px' : '0')};
    max-height: ${({ navActive }) => (navActive ? '59px' : '0')};
    transition: opacity 0.3s,
        visibility 0.3s
            ${({ navActive }) =>
                !navActive && ', max-width 0.1s 0.3s, max-height 0.1s 0.3s'};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        max-width: ${({ navActive }) => (navActive ? '63px' : '0')};
        max-height: ${({ navActive }) => (navActive ? '59px' : '0')};
    }
`;

const MenuLanguage = styled.div<{ device: Device }>`
    align-self: flex-end;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    display: ${({ device }) => (device === 'desktop' ? 'none' : 'flex')};
    text-transform: uppercase;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        display: ${({ device }) => (device === 'mobile' ? 'none' : 'flex')};
        flex: 1 1;
        padding-top: 4px;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        width: 75.5px;
        border-left: ${({ theme }) => theme.border};
    }
`;

const LanguageOption = styled.button<{ activeLanguage: boolean }>`
    text-decoration: ${({ activeLanguage }) =>
        activeLanguage ? 'underline' : 'none'};
    color: ${({ theme }) => theme.colors.black};
    font-style: italic;

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        padding: 1px 0;
    }
`;

const MenuLink = styled.button`
    height: 100%;
    padding-top: 6px;
    text-transform: uppercase;
    font-style: italic;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        padding-left: 12px;
    }
`;

const MenuItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 59px;
    background-color: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => theme.border};
    width: 285px;
    font-size: ${({ theme }) => theme.fontSizes.caps};
    line-height: ${({ theme }) => theme.lineHeights.large};
    transition: background-color 0.5s, transform 0.5s;
    margin-bottom: -23px;

    & ${MenuLink} {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        color: ${({ theme }) => theme.colors.black};
        flex: 1 1;

        @media screen and (min-width: ${({ theme }) =>
                theme.mediaQueries.large}) {
            justify-content: flex-start;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        justify-content: flex-start;
    }
`;

const ContactMenuItem = styled(MenuItem)`
    min-height: 100px;

    & :hover {
        transform: unset;
        background-color: ${({ theme }) => theme.colors.white};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        display: none;
    }
`;

const HoverArea = styled.div<{ contact?: boolean }>`
    z-index: ${({ theme }) => theme.zIndices.overlap};

    & :not(:last-child) {
        margin-bottom: -1px;
    }

    & :hover ${MenuItem} {
        ${({ contact }) =>
            !contact &&
            css`
                transform: translateY(-23px);
            `}
        background-color: ${({ theme }) => theme.colors.domas3};
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        & :hover ${MenuItem} {
            transform: translateX(70px);
        }
    }

    ${({ contact }) =>
        contact &&
        css`
            & ${MenuItem} {
                margin-bottom: 0;
                align-items: center;
                @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
                    align-items: flex-start;
                }
            }
        `}
`;

const CombinedButtonItem = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    & :first-child {
        flex: 1 1;
    }
`;

export default Menu;
