import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    @font-face {
        font-family: 'Sathu';
        src: local('Sathu'), url(./assets/fonts/sathu.ttf) format('truetype');
      }

    html {
        height: 100%;
        font-size: 10px;
        box-sizing: border-box;
        font-family: interstate, sans-serif;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    body {
        padding: 0;
        margin: 0;
    }

    a {
        text-decoration: none;
        color: currentColor;
    }

    p {
        margin: 0;
    }

    button {
        border: none;
        background-color: initial;
        cursor: pointer;
        padding: 0;

        :focus {
            outline: 1px solid #005fcc;
        }

        :focus:not(:focus-visible) {
            outline: 0;
        }

        :focus-visible {
            outline: 1px solid #005fcc;
        }
    }
`;

export default GlobalStyle;
