import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '../entities/UnreachableCaseError';

type TitleVariant = 'regular' | 'show' | 'world' | 'overview';

export interface Props extends HTMLAttributes<HTMLHeadingElement> {
    variant?: TitleVariant;
    as?: keyof Pick<JSX.IntrinsicElements, 'h2' | 'h3' | 'h4' | 'h5'>;
}

const Title: FC<Props> = ({ children, variant, as = 'h2', ...props }) => (
    <Inner as={as} variant={variant} {...props}>
        {children}
    </Inner>
);

const overviewCSS = css`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    line-height: ${({ theme }) => theme.lineHeights.medium};
`;

const regularCSS = css`
    font-size: ${({ theme }) => theme.fontSizes.caps};
    font-weight: ${({ theme }) => theme.fontWeights.regular};

    /* TODO: Overleggen met Domas over text styles voor window titles */
    /* text-transform: uppercase; */
    /* font-style: italic; */
`;

const worldCSS = css`
    font-size: ${({ theme }) => theme.fontSizes.world};
    line-height: ${({ theme }) => theme.lineHeights.world};
    font-weight: ${({ theme }) => theme.fontWeights.thin};
    margin: 0;
`;

const showCSS = css`
    font-size: 22px;
    line-height: 28px;
    font-style: italic;
    margin-bottom: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        font-size: ${({ theme }) => theme.fontSizes.huge};
        line-height: 46px;
        margin-bottom: 0;
    }
`;

const getCSS = (variant: TitleVariant) => {
    switch (variant) {
        case 'regular':
            return regularCSS;
        case 'world':
            return worldCSS;
        case 'show':
            return showCSS;
        case 'overview':
            return overviewCSS;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const Inner = styled.h2<Pick<Props, 'variant'>>`
    line-height: ${({ theme }) => theme.lineHeights.default};
    ${({ variant = 'regular' }) => getCSS(variant)}
`;

export default Title;
